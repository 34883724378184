<template>
  <BCard class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Tambah Device
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer
        ref="formRules"
        v-slot="{ invalid }"
      >
        <b-row>
          <b-col md="8">
            <!-- form -->
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Nama Device <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Nama Device"
                      rules="required"
                    >
                      <b-form-input
                        v-model="deviceName"
                        placeholder="Masukkan Nama Device"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Spesifikasi <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Spesifikasi"
                      rules="required"
                    >
                      <b-form-input
                        v-model="specification"
                        placeholder="Masukkan Spesifikasi"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Tipe <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Tipe"
                      rules="required"
                    >
                      <v-select
                        v-model="deviceType"
                        style="min-width: 150px"
                        :options="listType.slice(1)"
                        placeholder="Ketuk untuk memilih tipe device"
                        :reduce="data => data.value"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Partner <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Partner"
                      rules="required"
                    >
                      <v-select
                        v-model="noPartner"
                        :reduce="option => option.no_partner"
                        :options="listPartners"
                        placeholder="Ketik untuk mencari..."
                        @search="(search) => search.length > 0 ? onSearch(search) : ''"
                      />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Tanggal diterima <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Tanggal diterima"
                      :rules="'required'"
                    >
                      <flat-pickr
                        v-model="devicePickDate"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'j F Y',
                          dateFormat: 'Y-m-d',
                        }"
                        placeholder="Pilih Tanggal"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Attachment
                    </template>
                    <div class="d-flex gap-10">
                      <div class="w-100">
                        <validation-provider
                          #default="{ errors }"
                          name="Attachment"
                          rules="ext:jpg,png,jpeg"
                        >
                          <b-form-file
                            v-model="fileSelected"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="`Pilih atau drop file disini`"
                            drop-placeholder="Pilih atau drop file disini"
                            accept=".jpg, image/jpeg, image/png"
                            class="w-100"
                            @change="uploadFile($event)"
                          />
                          <small v-if="!errors[0]">*Upload dengan format png, jpg, jpeg</small>
                          <small
                            v-else
                            class="text-danger"
                          >{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
          <b-col md="4">
            <div class="device-preview d-flex items-center justify-center relative">
              <img
                v-if="fileSelected"
                :src="fileUrl(fileSelected)"
                alt="preview"
                class="image-preview"
              >
              <img
                v-else
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/image.svg"
                alt="no-image"
              >
              <div
                v-if="fileSelected"
                class="absolute __overlay items-center justify-center"
              >
                <button
                  class="d-flex justify-center items-center bg-gray-50 rounded px-2 py-[10px] gap-10"
                  @click="$bvModal.show('modalPreview')"
                >
                  <span class="k-eye" />
                  Lihat Foto
                </button>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
          <button
            v-b-modal.modalConfirmation
            type="kembali"
            class="mr-50 button-secondary"
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary w-[200px]"
            :disabled="invalid || loadingSubmit"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Submit
          </button>
        </div>
      </validation-observer>
    </b-overlay>
    <ModalConfirmation />
    <ModalPreview :image-url="fileSelected ? fileUrl(fileSelected) : null" />
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  min,
  max,
  alpha_spaces,
  numeric,
  ext,
} from '@validations'
import vSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import { capitalize } from '@/libs/helpers'
import { alertError, alertSuccess } from '@toast'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-select.scss'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { listType } from './config'
import ModalConfirmation from './ModalConfirmation.vue'
import ModalPreview from './ModalPreview.vue'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ModalConfirmation,
    ModalPreview,
    flatPickr,
  },
  data() {
    return {
      required,
      min,
      max,
      alpha_spaces,
      numeric,
      ext,

      capitalize,
      alertError,
      alertSuccess,

      loading: false,
      loadingSubmit: false,
      submitErrors: '',

      deviceName: '',
      specification: '',
      deviceType: '',
      noPartner: '',
      devicePickDate: '',
      fileSelected: null,
      fileExtension: null,

      listPartners: [],
      listType,
    }
  },
  computed: {
    editAction() { return this.id !== undefined },
  },
  async mounted() {
    this.getListPartner('')
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const formData = new FormData()
      formData.append('device_name', this.deviceName)
      formData.append('specification', this.specification)
      formData.append('device_type', this.deviceType)
      formData.append('no_partner', this.noPartner)
      formData.append('device_pick_date', this.devicePickDate)

      if (this.fileSelected) formData.append('attachment', this.fileSelected)

      const url = 'v1/devices/store'
      await komtimAxiosIns
        .post(url, formData)
        .then(() => {
          const text = 'Berhasil menambahkan data'
          this.alertSuccess(text)

          this.$router.push({ name: 'device' })
        })
        .catch(error => {
          this.alertError(error)

          if (error.response.status === 422) {
            this.submitErrors = Object.fromEntries(
              Object.entries(error.response.data.data).map(
                ([key, value]) => [key, value[0]],
              ),
            )
          }
        })
    },
    async getListPartner(keyword) {
      const params = `&limit=20&keyword=${keyword}`
      const url = `v1/partners?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listPartners = data.map(e => ({ ...e, label: `${e.no_partner} - ${e.name}` }))
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchPartner: _.debounce((search, it) => {
      it.getListPartner(search)
    }, 500),
    onSearch(search) {
      this.searchPartner(search, this)
    },
    uploadFile(e) {
      const fileSelected = e.target.value
      const fileExtension = fileSelected.substr((fileSelected.lastIndexOf('.') + 1))
      this.fileExtension = fileExtension
      if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
        this.fileSelected = null
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi .png, .jpg')
      }
    },
    fileUrl: file => URL.createObjectURL(file),
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

.vs__dropdown-menu {
  max-height: 230px;
}
.device-preview {
  height: 100%;
  width: 100%;
  background-color: #f4f4f4;
  border: 1px solid #828282;
  border-radius: 16px;
  overflow: hidden;
}
.image-preview {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.__overlay {
  display: none;
  background: #33333340;
  border-radius: 16px;
  border: 1px solid #828282;
  transition: ease-in-out .5;
}
.device-preview:hover > .__overlay {
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
